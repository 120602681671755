import React, { useState, useEffect } from 'react'

import api from '../../services/api'
import ProjectList from '../ProjectList'
import Loader from '../Loader'
import './styles.css'

const Work = () => {
  const [projects, setProjects] = useState([])

  async function getProjects() {
    // Implement authentication through github graphql v4 API
    const user = await api.get('/user')

    const query = `
      query {
        node(id: "${user.data.node_id}") {
          ... on User {
            name
            login
            bio
            repositories(
              first: 20
              orderBy: {
                field: CREATED_AT
                direction: DESC
              }
            ) {
              nodes {
                name
                description
                url
                createdAt
                updatedAt
                isPrivate
                languages(
                  first: 2
                  orderBy: {
                          field: SIZE
                          direction: DESC
                  }
                ) {
                  totalSize
                  edges {
                    size
                    node {
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
    `

    const repos = await api.post('/graphql', { query })

    setProjects(repos.data.data.node.repositories.nodes)
  }

  useEffect(() => {
    getProjects()
  }, [])

  return projects.length < 1 ? (
    <Loader />
  ) : (
    <div>
      <h2>projects</h2>
      <p>
        this portal was created to transfer knowledge. below you'll find the
        main projects developed by us to contribute to the world.
      </p>

      <div className="lists-grid">
        {['web', 'open', 'educational'].map((type) => (
          <ProjectList key={type} projects={projects} projectType={type} />
        ))}
      </div>
    </div>
  )
}

export default Work
