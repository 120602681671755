import React from 'react'

import './styles.css'

const About = () => {
  return (
    <div className="about grid">
      <div className="banner-img"></div>
      <h2>about</h2>
      <q>
        <i>
          brazilian, Fluminense, born and raised in the Santa Teresa
          neighborhood - famous for it's historic architecture, gastronomy,
          nature and of course the eletric tram in the picture above.
          <br />
          musician, comunicative sportsman, enthusiastic student and proactive
          insistent, I'm a producer and organizational manager & leader.
          <br />
          salesman, producer, composer, programmer, systems developer, issues
          analist and also designer. done much, became much.
          <br />
          <br />
          all my activities in life so far were very diverse, and I worked in
          a autonomous way for most of the time.
          <br />
          in the beginning I had several sales experiences, after that I
          seeked autonomy and executed the technical and production tasks
          described above.
          <br />
          <br />
          fluent in English, with professional, formal, and informal vocabulary, my
          Spanish is in an intermediate level with informal vocabulary.
          <br />
          currently learning Latin and Greek, I had classes in French, Mandarim,
          Italian and Deutsch.
          <br />
          <br />
          my values ressonate with moral Catholic pilars, considering Brazil's
          cultural identity - based also in Roman laws and Greek philosophy.
          <br />
          seeing the world through the counter-revolution movement optics, in
          favor of anti-igualitarism and anti-liberalism I stand against the
          rotting evil of human ideas corruption.
          <br />
          <strong>
            I believe that we shall move safely towards progress through
            maintaining real traditional values always with an eye pointed at
            the Good, the Beauty, and the Truth.
          </strong>
          <br />
          these aren't subjective concepts.
          <br />
          <br />
          I have a beautiful family supporting my existence, with a gourgeous
          wife who is my partner in every way, in all moments of all my days.
          <br />
          my life is balanced between phisical activities, I.T. work and
          studies in many different matters (philosophy, geopolitics, cultural
          identities, humanities and such).
          <br />
          <br />
          gratefull and patiently I keep moving along in time
          <br />
          <br />
          <br />
          sincerely,
          <br />
          jplps
        </i>
      </q>
    </div>
  )
}

export default About
