import React, { useState, useEffect } from 'react'

import './styles.css'
import api from '../../services/api'
import Loader from '../../components/Loader'

const Project = ({ match }) => {
  const [project, setProject] = useState({
    readme: 'loading',
  })

  useEffect(() => {
    api
      .get(`/repos/jplps/${match.params.projectName}/readme`, {
        headers: {
          Accept: 'application/vnd.github.v3.html',
        },
      })
      .then((res) => {
        setProject({ readme: res.data })
      })
  }, [match.params.projectName])

  return (
    <>
      {match.isExact && project.readme === 'loading' ? (
        <Loader />
      ) : (
        <div className="readme">
          <div
            className="project-container grid"
            dangerouslySetInnerHTML={{ __html: project.readme }}
          />
          <p className="repo-link">
            Check the{' '}
            <a href={`https://github.com/jplps/${match.params.projectName}`}>
              Repo
            </a>
            !
          </p>
        </div>
      )}
    </>
  )
}

export default Project
