import React, { FunctionComponent } from 'react'

import ThemeContext from '../../context/ThemeContext'

import './styles.css'

const setLightByTheme = (theme) => {
  return theme.dark ? 'dark' : 'light'
}

const Layout: FunctionComponent = ({ children }) => (
  <ThemeContext.Consumer>
    {(theme) => (
      <div className={setLightByTheme(theme)}>
        <header>
          <a href="/">
            panga<span>e</span>a
          </a>
          <em>modern development</em>
        </header>

        <main>{children}</main>

        <footer>
          <p className="final-message"><abbr title="established">est</abbr> 2018</p>
	  
          <a
            href="https://t.me/joaopedrolps"
            dangerouslySetInnerHTML={{ __html: '&#9742;' }}
          />

	  <a
            href="mailto:contact@pangaea.com.br"
            dangerouslySetInnerHTML={{ __html: '&#64;' }}
          />
        </footer>
      </div>
    )}
  </ThemeContext.Consumer>
)

export default Layout
