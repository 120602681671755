import React from 'react'

import Work from '../../components/Work'
import About from '../../components/About'

import './styles.css'

const Home = () => {
  return (
    <div className="home grid">
      <About />
      <Work />
    </div>
  )
}

export default Home
