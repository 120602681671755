import React from 'react'

const momentTime = new Date().getHours()
const isDark = momentTime < 6 || momentTime > 12
const defaultState = {
  dark: isDark,
}

const ThemeContext = React.createContext(defaultState)

class ThemeProvider extends React.Component {
  render() {
    const { children } = this.props
    const { dark } = this.context

    return (
      <ThemeContext.Provider value={{ dark }}>{children}</ThemeContext.Provider>
    )
  }
}

export default ThemeContext
export { ThemeProvider }
