import React from 'react'
import { BrowserRouter as AppRouter, Switch, Route } from 'react-router-dom'

import Layout from './components/Layout'
import Home from './pages/Home'
import Project from './pages/Project'

const App = () => {
  return (
    <AppRouter>
      <Layout>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route
            path={`${process.env.PUBLIC_URL}/:projectName`}
            component={Project}
          />
        </Switch>
      </Layout>
    </AppRouter>
  )
}

export default App
