import React from 'react'

import './styles.css'
import ThemeContext from '../../context/ThemeContext'

const Loader = () => (
  <ThemeContext.Consumer>
    {(theme) => (
      <div
        className="loader"
        style={{ color: theme.dark ? 'var(--green)' : 'var(--gray)' }}
      />
    )}
  </ThemeContext.Consumer>
)

export default Loader
