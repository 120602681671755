import React from 'react'

import ThemeContext from '../../context/ThemeContext'
import './styles.css'

const typesDictionary = {
  web: {
    display: 'private solutions',
    repos: ['arcaffo', 'casasantathereza', 'pangaea'],
  },
  open: {
    display: 'contribute',
    repos: ['jobster', 'joker', 'spectrum', 'soapers', 'tasking'],
  },
  educational: {
    display: 'shared knowledge',
    repos: ['dot-files', 'programming', 'web-development'],
  },
}

const ProjectList = ({ projects, projectType }) => {
  const curated = projects.filter((project) =>
    typesDictionary[projectType].repos.includes(project.name)
  )

  const findPercentage = (size, totalSize) => {
    const percentage = (size * 100) / totalSize
    return percentage.toFixed(0)
  }

  const setCuratedLanguages = (repo) => {
    return repo.languages.edges.map((language) => (
      <span key={Math.random()}>
        {findPercentage(language.size, repo.languages.totalSize)}%{' '}
        {language.node.name}
      </span>
    ))
  }

  return (
    <div className="repo-list grid">
      <div className="mural-title">
        <h3>{projectType}</h3>
        <span>{typesDictionary[projectType].display}</span>
      </div>

      <ThemeContext.Consumer>
        {(theme) =>
          curated.map((repo) => (
            <a
              key={repo.name}
              href={
                projectType === 'web'
                  ? `https://${repo.name}.com.br`
                  : `/${repo.name}`
              }
              className="repo-display"
            >
              <h3
                style={{ color: theme.dark ? 'var(--green)' : 'var(--grayer)' }}
              >
                {repo.name}
              </h3>

              <p>{repo.description}</p>

              <div className="info-display">
                <div className="date-display">
                  <span>
                    &#9733; {new Date(repo.createdAt).toLocaleDateString()}
                  </span>
                  <span>
                    &#8635; {new Date(repo.updatedAt).toLocaleDateString()}
                  </span>
                </div>

                <div className="languages-display">
                  {setCuratedLanguages(repo)}
                </div>
              </div>
            </a>
          ))
        }
      </ThemeContext.Consumer>
    </div>
  )
}

export default ProjectList
